<template lang="pug">
	#integration-form-root
		#integration-form-section-1
			.widget
				el-card.box-card
					el-row(slot="header")
						el-col.text-left(:span="24")
							.d-flex.justify-content-between
								h3
									b Integrations - {{ this.type }} entity

					el-form(:form="form" )
						el-row.text-left(:gutter="12")
							h5 Basic Details
							p In this part you must set the basic integration details and choose the integration type. Take a note that integration type that is not listed in the droplist (when you click on the input or type something in it).&nbsp;
								| In this case you must create an integration and add another file that describes the fields that are used in it.
							p Current status:&nbsp;
								b(v-bind:class="{'text-success': integrationExists, 'text-danger': !integrationExists}") {{ integrationExists ? 'EXISTS' : 'NOT EXISTS' }}
								el-tooltip(v-if="integrationExists" content="Click to show fields")
									font-awesome-icon.icon.alt.ml-3.chooseable(size="xl", :icon="['fas', 'circle-question']", @click="showEligibleFields")
						el-row.mt-3(:gutter="12")
							el-col(:xs="24", :sm="12" :md="6" x)
								el-form-item(label="CRM / Integration Type" label-position="top", :error="errors.type.s ? errors.type.m : ''" v-bind:class="{'is-invalid': errors.type.s}")
									el-autocomplete.w-100( class="inline-input" :fetch-suggestions="querySearch" v-model="form.type" placeholder="CRM Type" @select2="itgTypeChosed" @blur="itgTypeChosed")
							el-col(:xs="24", :sm="12" :md="6")
								el-form-item(label="Brand" :error="errors.brand_id.s ? errors.brand_id.m : ''" v-bind:class="{'is-invalid': errors.brand_id.s}")
									el-select.w-100(filterable="", v-model="form.brand_id" fi)
										el-option(value="" label="- Select Brand -")
										el-option(v-for="o of brandOptions" :value="o.v" :label="o.t")
							el-col(:xs="24", :sm="12" :md="12")
								el-form-item(label="API URL"	:error="errors.api_url.s ? errors.api_url.m : ''" v-bind:class="{'is-invalid': errors.api_url.s}")
									el-input(v-model="form.api_url" placeholder="URL to the REST API")
							el-col(:xs="24", :sm="12" :md="6")
								el-form-item(label="Short Note")
									el-input(v-model="form.note" placeholder="Short Note" :maxlength="64")
						el-divider
						el-row.text-left(:gutter="12")
							el-col(:span="24")
								h5
									b Password Validation
								p Password validation is a feature that allows you to set a validation pattern for the password that the user enters when registering. The pattern is a regular expression (RegEx) that is checked against the password.&nbsp;
									| If the password does not match the pattern, the user will not be able to register and will receive an error message. The error message can be customized.
						el-row(:gutter="12")
							el-col(:xs="24", :sm="12" :md="4")
								el-form-item(label="Password With Sign" )
									el-switch.w-100(v-model="form.password_with_sign_flag" active-text="Enabled" inactive-text="Disabled" )
							el-col(:xs="24", :sm="12" :md="4")
								el-tooltip()
									template(slot="content")
										p <b>Generated password rules</b> - The generated password will contain:
											ul
												li One uppercase letter (A-Z)
												li 8 lowercase letters (a-z)
												li at least one exclaimation mark (!) if the flag "Password With Sign" is <b class="text-success">enabled</b> (E.g - <span class="text-warning">Xtcw8ubgp!</span>)
												li at least one exclaimation mark (!) if the flag "Password With Sign" is <b class="text-danger">disabled</b> (E.g - <span class="text-warning">Whw7v2ewr</span>)
									el-form-item(label="Custom Password Validation" )
										el-switch.w-100(v-model="form.password_validation_pattern_flag" active-text="Enabled" inactive-text="Disabled" @change="resetPasswordElements")
							el-col(:xs="24", :sm="12" :md="6")
								el-form-item(label="Password Validation RegEx code"	:error="errors.password_validation_pattern_string.s ? errors.password_validation_pattern_string.m : ''" v-bind:class="{'is-invalid': errors.password_validation_pattern_string.s}")
									el-input(v-model="form.password_validation_pattern_string" placeholder="Password Validation RegEx Pattern" :disabled="!form.password_validation_pattern_flag")
							el-col(:xs="24", :sm="12" :md="6")
								el-form-item(label="Password Validation Message"	:error="errors.password_validation_pattern_message.s ? errors.password_validation_pattern_message.m : ''" v-bind:class="{'is-invalid': errors.password_validation_pattern_message.s}")
									el-input(v-model="form.password_validation_pattern_message" placeholder="Password Validation Message" :disabled="!form.password_validation_pattern_flag")
						el-divider
						el-row.mt-3(:gutter="12")
							el-col(:span="24")
								//el-form-item(label="API Endpoints")
								h5.text-left
									b API Endpoints (For Developers)
						el-row.mt-2(:gutter="12")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Create Lead Endpoint" :error="errors.create_lead.s ? errors.create_lead.m : ''" v-bind:class="{'is-invalid': errors.create_lead.s}")
									el-input(v-model="form.create_lead" placeholder="Create Lead")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Create Customer Endpoint" :error="errors.create_customer.s ? errors.create_customer.m : ''" v-bind:class="{'is-invalid': errors.create_customer.s}")
									el-input(v-model="form.create_customer" placeholder="Create Customer ")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get By Mail Endpoint" :error="errors.get_by_email.s ? errors.get_by_email.m : ''" v-bind:class="{'is-invalid': errors.get_by_email.s}")
									el-input(v-model="form.get_by_email" placeholder="Find By Email ")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get By CID Endpoint" :error="errors.get_by_cid.s ? errors.get_by_cid.m : ''" v-bind:class="{'is-invalid': errors.get_by_cid.s}")
									el-input(v-model="form.get_by_cid" placeholder="Find By CID ")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Deposits Endpoint" :error="errors.get_deposits.s ? errors.get_deposits.m : ''" v-bind:class="{'is-invalid': errors.get_deposits.s}")
									el-input(v-model="form.get_deposits" placeholder="Get Deposits ")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Balances Endpoint" :error="errors.get_balances.s ? errors.get_balances.m : ''" v-bind:class="{'is-invalid': errors.get_balances.s}")
									el-input(v-model="form.get_balances" placeholder="Get Balance ")
						el-divider(v-if="form.type === 'api-partners'")
						el-row(v-if="form.type === 'api-partners'" :gutter="12")
							el-col.mt-2(:span="24")
								//el-form-item(label="API Endpoints - API Partners only")
								h5.text-left
									b API Endpoints - API Partners only
						el-row.mt-2(v-if="form.type === 'api-partners'" :gutter="12")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Close CFD Action Endpoint" )
									el-input(v-model="form.close_cfd_action" placeholder="Close CFD Action")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Edit CFD Action Endpoint" )
									el-input(v-model="form.edit_cfd_action" placeholder="Edit CFD Action")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Asset Rate Endpoint" )
									el-input(v-model="form.get_asset_rate" placeholder="Get Asset Rate")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Assets (Symbols) Endpoint" )
									el-input(v-model="form.get_assets" placeholder="Get Assets (Symbols)")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get CFD Options Endpoint" )
									el-input(v-model="form.get_cfd_options" placeholder="Get CFD Options")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Options (Rates) Endpoint" )
									el-input(v-model="form.get_options" placeholder="Get Options (Rates)")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Orders Endpoint" )
									el-input(v-model="form.get_orders" placeholder="Get Orders")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Get Trade Actions Endpoint" )
									el-input(v-model="form.get_trade_actions" placeholder="Get Trade Actions (Trades)")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Place CFD Action Endpoint" )
									el-input(v-model="form.place_cfd_action" placeholder="Place CFD Action")
							el-col(:xs="24" :sm="6")
								el-form-item(label="Place Order Endpoint" )
									el-input(v-model="form.place_order" placeholder="Place Order")

						el-divider
						el-row(:gutter="12")
							el-col(:span="24")
								h5.text-left
									b Custom Links (For Developers)
						el-row(:gutter="12")
							el-col(:xs="24" )
								el-form-item()
									template(slot="label")
										label Custom API endpoints - keys must differ from the above, otherwise can override them.&nbsp;
											el-tooltip(:content="keysToIgonre.join(', ')")
												span.chooseable Hover the question icon to view the keys.
									.d-flex.justify-content-between5
										el-tooltip(:content="keysToIgonre.join(', ')")
											i.el-icon-question.chooseable.mr-3
										el-tooltip(content="Add a new line")
											el-button(type="info" icon="el-icon-plus" @click="addRow('links')") Add
							el-col(:xs="24" :md="18" :lg="12")
								el-empty.p-0(description="No Records..." :image-size="50" v-if="form.links.length === 0")
								el-row.mt-1.mb-1(v-for="(o, i) of form.links" :gutter="6")
									el-col(:span="4")
										el-input(v-model="o.key" placeholder="Key")
									el-col(:span="19")
										el-input(v-model="o.value" placeholder="Value")
									el-col(:span="1")

										el-tooltip(content="Remove this entry")
											el-button(icon="el-icon-delete" type="danger" @click="shrinkLinksArray(i)")

						el-divider
						el-row(:gutter="12")
							el-col(:span="24")
								h5.text-left
									b Integration Params, Credentials and Misc
						el-row.text-left.mt-3()
							el-col(:xs="24" :sm="12" :md="8")
								el-form-item()
									template(slot="label")
										span Fields to avoid&nbsp;
											small.text-success (These fields will not be sent to the brands)
									el-select.w-100(multiple="" filterable="" allow-create="" placeholder="Fields that you want to avoid sending" v-model="form.ignore_fields_list")
										el-option(label="Select from the list or add new" value="null" disabled="")
										el-option(v-for="o of typeAvoidOptions" :label="o" :value="o")
						el-row.text-left.mt-3(:gutter="12")
							h5 Instructions
							p The parameters part is devided to 2 parts - MISC and CREDS. Use CREDS for sensitive data and data related to authenticate to the brand's API and MISC params for other miscellaneous situations.&nbsp;
							p.text-success
								i Note: You can add dynamic properties to the request payload by adding a prefix "#key_" to a param name, so, the property without the prefix will be added to the payload as a MISC param.
						el-row.text-left.mt-3(:gutter="12")
							el-col(:span="24")
								el-form-item(label="MISC Params")
									el-tooltip(content="Add a new line")
										el-button(type="info" icon="el-icon-plus" @click="addRow('misc')") Add
								el-empty.p-0(description="No Records..." :image-size="50" v-if="form.misc.length === 0")
								el-row.mt-1.mb-1(v-for="(o, i) of form.misc" :gutter="6")
									el-col(:span="8")
										el-input(v-model="o.key" placeholder="Key")
									el-col(:span="15")
										el-input(v-model="o.value" placeholder="Value")
									el-col(:span="1")
										el-tooltip(content="Remove this entry")
											el-button(icon="el-icon-delete" type="danger" @click="() => form.misc.splice(i,1)")
						el-divider

						el-row.text-left(:gutter="12")
							el-col(:span="24")
								el-form-item(label="Creds Params")
									el-tooltip(content="Add a new line")
										el-button(type="info" icon="el-icon-plus" @click="addRow('creds')") Add
								el-empty.p-0(description="No Records..." :image-size="50" v-if="form.creds.length === 0")
								el-row.mt-1.mb-1(v-for="(o, i) of form.creds" :gutter="6")
									el-col(:span="8")
										el-input(v-model="o.key" placeholder="Key")
									el-col(:span="15")
										el-input(v-model="o.value" placeholder="Value")
									el-col(:span="1")
										el-tooltip(content="Remove this entry")
											el-button(icon="el-icon-delete" type="danger" @click="() => form.creds.splice(i,1)")

						el-divider

						el-row(:gutter="48")
							el-col(:sm="24" :md="10" :xl="8" )
								el-row.text-left()
									h5 Proxy
									p Setup and use a proxy to send the leads to the brand. Supported proxy protocols are HTTP, HTTPS and SOCKS5.
								el-row.mt-3(:gutter="12")
									.d-flex.justify-content-between
										h5.header-text Proxy Settings
										div
											label.label-text-gray.d-inline Toggle Proxy:&nbsp;&nbsp;
											el-radio-group(size="mini" v-model="form.proxy_toggle" :fill="form.proxy_toggle === true ? '#409EFF' :  'red'")
												el-radio-button.mb-0(:label="true") On
												el-radio-button.mb-0(:label="false") Off
								el-row.mt-3(:gutter="12")
									el-col(:xs="24" :md="8")
										el-form-item(label="Protocol" :error="form.proxy_protocol === 'HTTPS' ? 'HTTPS currently not supported!' : ''" )
											el-select.w-100(size="mini" v-model="form.proxy_protocol" :disabled="!form.proxy_toggle")
												el-option(label="SOCKS5" value="SOCKS5" selected="")
												el-option(label="HTTP" value="HTTP")
												el-option(label="HTTPS" value="HTTPS")
									el-col(:xs="24" :md="10" :lg="11")
										el-form-item(label="Host")
											el-input(size="mini" v-model="form.proxy_host" :disabled="!form.proxy_toggle" placeholder="IPv4 address of the proxy" )
									el-col(:xs="24" :md="6" :lg="5")
										el-form-item(label="Port" )
											el-input-number.w-100(size="mini" v-model="form.proxy_port" controls-position="right" :min="0" :max="65536" :disabled="!form.proxy_toggle")

									el-col(:xs="24" :md="8" :lg="6")
										el-form-item(label="Toggle Auth")
											el-switch.w-100(size="mini" v-model="form.proxy_auth_toggle" active-text="On" inactive-text="Off" :disabled="!form.proxy_toggle")

									el-col(:xs="24" :md="8" :lg="9")
										el-form-item(label="Auth - Username")
											el-input(size="mini" v-model="form.proxy_auth_username" :disabled="!form.proxy_toggle || !form.proxy_auth_toggle" placeholder="Username" )
									el-col(:xs="24" :md="8" :lg="9")
										el-form-item(label="Auth - Password")
											el-input(size="mini" v-model="form.proxy_auth_password" :disabled="!form.proxy_toggle || !form.proxy_auth_toggle" placeholder="Password"  type="password" show-password="")

							el-col(:sm="24" :md="14" :xl="16" )
								el-row.text-left()
									h5 Working Hours
									p Set the working hours and toggle it on or off. If a lead is registered not in the working hours, from his perspective he will be registered on a normal basis (will be redirected to the "thankyou page"), but, will be saved as a failed lead with the failed reason of "out of working hours" and won't send it to the brand.
									p The working hours are set for each day of the week. If you toggle of a day, the brand won't receive leads on that day.
									p.text-success
										i *Note: The timezone of the working hours is the server's timezone which is UTC+3
								el-row(:gutter="12")
									el-col(:xs="12" :sm="6" )
										el-form-item(label="Toggle working hours" position="left")
											el-switch(v-model="form.working_hours_flag" active-text="On" inactive-text="Off" )



								el-row(:gutter="12" v-if="form.working_hours_flag")
									el-col(:span="24")
										el-row.mt-2(:gutter="12" v-for="row of form.working_hours_list" :key="row.label")
											el-col.text-left(:xs="12" :sm="6" :md="4" :xl="2")
												label.label-color-override {{row.label}}
											el-col(:xs="12" :sm="6" :md="4" :xl="2")
												el-switch.w-100(v-model="row.flag" active-text="On" inactive-text="Off" )

											el-col(v-if="row.flag" :xs="12" :sm="6" :md="4")
												el-time-select.w-100(v-model="row.start" placeholder="Start time" size="mini" :picker-options="timePickerOptions.full")
											el-col(v-if="row.flag" :xs="12" :sm="6" :md="4")
												el-time-select.w-100(v-model="row.end" placeholder="End time" size="mini" :picker-options="timePickerOptions.full")

										//pre form.working_hours_flag{{form.working_hours_flag}}
										//pre form.working_hours_list{{form.working_hours_list}}

						el-row.mt-5(:gutter="12" justify="between")
							el-button(@click="submit" type="primary") Save
							el-button(type="danger" @click="$router.push({name: 'iframe-list'})") Cancel

		#integration-form-section-2
			el-dialog.override-width.search-dialog(title="Integration Eligible Fields"	:visible.sync='showEligibleFieldsModal', width='75%', center='' @closed="() => this.showEligibleFieldsModal = false")
				el-row(:gutter="24")
					el-col(:span="24")
						label Body
					el-col(:span="24")
						label.text-info(v-if="currentIntegrationFields.list.body.length === 0") No fields in this section...
						el-tooltip(v-for="tag of currentIntegrationFields.list.body")
							template(slot="content")
								b Key:&nbsp;
								span {{tag.key}}
								br
								b Type:&nbsp;
								span {{tag.type}}
								br
								b Mandatory:&nbsp;
								span {{tag.mandatory}}
								br
								b Body / Param type:&nbsp;
								span {{tag.params}}
								br
								b(v-if="tag.father") Related to field:&nbsp;
								span(v-if="tag.father") {{tag.father}}
								br(v-if="tag.father")
								b(v-if="tag.index") Index in array:&nbsp;
								span(v-if="tag.index") {{tag.index}}
								br(v-if="tag.index")

							.mr-3.d-inline(v-if="tag.mandatory")
								el-badge(is-dot="" )
									el-tag() {{ tag.key }}
							.mr-3.d-inline
								el-tag() {{ tag.key }}
				el-row.mt-3(:gutter="24")
					el-col(:span="24")
						label Misc
					el-col(:span="24")
						label.text-info(v-if="currentIntegrationFields.list.misc.length === 0") No fields in this section...
						el-tooltip(v-for="tag of currentIntegrationFields.list.misc")
							template(slot="content")
								b Key:&nbsp;
								span {{tag.key}}
								br
								b Type:&nbsp;
								span {{tag.type}}
								br
								b Mandatory:&nbsp;
								span {{tag.mandatory}}
								br
								b Body / Param type:&nbsp;
								span {{tag.params}}
								br
							.mr-3.d-inline(v-if="tag.mandatory")
								el-badge(is-dot="" )
									el-tag(type="danger" ) {{ tag.key }}
							.mr-3.d-inline
								el-tag(type="danger" ) {{ tag.key }}
				el-row.mt-3(:gutter="24")
					el-col(:span="24")
						label Creds
					el-col(:span="24")
						label.text-info(v-if="currentIntegrationFields.list.creds.length === 0") No fields in this section...
						el-tooltip(v-for="tag of currentIntegrationFields.list.creds")
							template(slot="content")
								b Key:&nbsp;
								span {{tag.key}}
								br
								b Type:&nbsp;
								span {{tag.type}}
								br
								b Mandatory:&nbsp;
								span {{tag.mandatory}}
								br
								b Body / Param type:&nbsp;
								span {{tag.params}}
								br
							.mr-3.d-inline(v-if="tag.mandatory")
								el-badge(is-dot="" v-if="tag.mandatory")
									el-tag(type="success" ) {{ tag.key }}
							.mr-3.d-inline
								el-tag(type="success" ) {{ tag.key }}



</template>


<style lang="scss">

</style>


<script lang="js">
import Vue from 'vue';
import validator from 'validator';
import { debounce } from 'vue-debounce'
import {isValidRegex} from "@/utils/validate";
import {daysOfWeek, timePickerOptions, workingDaysOfWeek} from "@/struct";

let keysToIgonre = [
		'host',
	'closeCfdAction',
	'editCfdAction',
	'getAssetRate',
	'getAssets',
	'getCfdOptions',
	'getOptions',
	'getOrders',
	'getTradeActionsReport',
	'placeCfdAction',
	'placeOrder',
	'createLead',
	'createCustomer',
	'getByEmail',
	'getByMail',
	'getByCid',
	'getDeposits',
	'getBalances',
]

const $currentIntegrationFields = {
		hash: { body: {}, misc: {}, creds: {} },
		list: { body: [], misc: [], creds: [] }
}

export default {
	name: 'integration-form',
	async beforeRouteEnter(to, from, next) {
		if (to.name === 'iframe-add-integration' && !to.query.id) {
			try {
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: 'a371666a-a396-4137-b92c-6c1fc201e671,49f06cec-46b0-4237-bf9a-d4d3969e5af6'})
				next(vm => {
					vm.typeOptions = options.crm_types;
					vm.brandOptions = options.brand;
				})
			} catch (e) {
				console.error(e);
				Vue.router.push({name: 'brand-list'})
			}
		} else if (to.name === 'iframe-edit-integration' || (to.name === 'iframe-add-integration' && to.query.id)) {
			try {
				let type = (to.name === 'iframe-add-integration' && to.query.id) ? 'query' : 'params'
				let result = await Vue.apix.sendHttpRequest('GET', 'integration/get', {id: to[type].id});
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', {filters: 'a371666a-a396-4137-b92c-6c1fc201e671,49f06cec-46b0-4237-bf9a-d4d3969e5af6'})
				let itg = await Vue.apix.sendHttpRequest('GET','integration/integration-fields/' + (result.type || ''))



				next(vm => {
					vm.typeOptions = options.crm_types;
					vm.brandOptions = options.brand;
					vm.counter = {
						misc: 1,
						creds: 1,
						links: 0
					},
							//======//
							vm.integrationExists = itg.exists;
						vm.currentIntegrationFields = JSON.parse(JSON.stringify($currentIntegrationFields));
						if(itg.content)
									Object.values(itg.content).forEach(e => {
										vm.currentIntegrationFields.hash[e.params][e.key] = e;
										vm.currentIntegrationFields.list[e.params].push(e);
										vm.typeAvoidOptions.push(e.key);
									})

						vm.typeAvoidOptions = [...vm.typeAvoidOptions.sort()]
						//======//
							vm.integrationExists = itg.exists;
							vm.entity_id = to[type].id,
							vm.type = (to.name === 'iframe-add-integration' && to.query.id) ? 'clone' : 'edit',
							vm.form = {
								ignore_fields_list: result.ignore_fields_list,
								brand_id: result.brand_id || '',
								type: result.type || '',
								api_url: result.api_url || '',
								creds: [],
								misc: [],
								links: [],
								note: result.note,
								create_lead: result.misc.api_url.create_lead || result.misc.api_url.createLead || '',
								create_customer: result.misc.api_url.create_customer || result.misc.api_url.createCustomer || '',
								get_by_email: result.misc.api_url.get_by_email || result.misc.api_url.getByMail || '',
								get_by_cid: result.misc.api_url.get_by_id || result.misc.api_url.getByCid || '',

								// Extra
								get_deposits: result.misc.api_url.get_deposits || result.misc.api_url.getDeposits || '',
								get_balances: result.misc.api_url.get_balances || result.misc.api_url.getBalances || '',

								// Password validation
								password_validation_pattern_flag: result.password_validation_pattern_flag || false,
								password_validation_pattern_string: result.password_validation_pattern_string || '',
								password_validation_pattern_message: result.password_validation_pattern_message || '',
								password_with_sign_flag: result.password_with_sign_flag || false,

								// working hours
								working_hours_list: result.working_hours_list,
								working_hours_flag: result.working_hours_flag,

								// Proxy
								proxy_toggle: result.proxy_toggle,
								proxy_protocol: result.proxy_protocol,
								proxy_host: result.proxy_host,
								proxy_port: result.proxy_port,
								proxy_auth_toggle: result.proxy_auth_toggle,
								proxy_auth_username: result.proxy_auth_username,
								proxy_auth_password: result.proxy_auth_password,
							};

					Object.entries(result.misc.api_url).forEach(e => {
						if(!keysToIgonre.includes(e[0])) {
							vm.counter.links++;
							vm.form.links.push({key: e[0], value: e[1]})
						}
					})


					// Api Partners - Only relevant to API Partners
					if (result.misc.api_url.closeCfdAction) vm.form.close_cfd_action = result.misc.api_url.closeCfdAction;
					if (result.misc.api_url.editCfdAction) vm.form.edit_cfd_action = result.misc.api_url.editCfdAction;
					if (result.misc.api_url.getAssetRate) vm.form.get_asset_rate = result.misc.api_url.getAssetRate;
					if (result.misc.api_url.getAssets) vm.form.get_assets = result.misc.api_url.getAssets;
					if (result.misc.api_url.getCfdOptions) vm.form.get_cfd_options = result.misc.api_url.getCfdOptions;
					if (result.misc.api_url.getOptions) vm.form.get_options = result.misc.api_url.getOptions;
					if (result.misc.api_url.getOrders) vm.form.get_orders = result.misc.api_url.getOrders;
					if (result.misc.api_url.getTradeActionsReport) vm.form.get_trade_actions = result.misc.api_url.getTradeActionsReport;
					if (result.misc.api_url.placeCfdAction) vm.form.place_cfd_action = result.misc.api_url.placeCfdAction;
					if (result.misc.api_url.placeOrder) vm.form.place_order = result.misc.api_url.placeOrder;

					vm.form.creds = Object.entries(result.creds).map(e => Object.assign({}, {key: e[0], value: e[1]}));
					let misc = {...result.misc};
					delete misc.api_url;
					vm.form.misc = Object.entries(misc).map(e => Object.assign({}, {key: e[0], value: e[1]}));
				})
			} catch (e) {
				console.error(e);
				Vue.router.push({name: 'brand-list'})
			}
		}
	},

	data() {
		return {
			daysOfWeek,
			timePickerOptions,
			type: 'create',
			keysToIgonre,
			counter: {
				misc: 1,
				creds: 1,
				links: 1
			},
				showEligibleFieldsModal: false,
				integrationExists: false,
				currentIntegrationFields: JSON.parse(JSON.stringify($currentIntegrationFields)),
			form: {
				brand_id: '',
				type: '',
				api_url: '',
				links: [],
				creds: [],
				misc: [],
				note: '',
				create_lead: '',
				create_customer: '',
				get_by_email: '',
				get_by_cid: '',
				ignore_fields_list: '',

				// Extra
				get_deposits: '',
				get_balances: '',

				// Password validation
				password_validation_pattern_flag: false,
				password_validation_pattern_string: '',
				password_validation_pattern_message: '',
				password_with_sign_flag: false,

				// working hours
				working_hours_flag: false,
				working_hours_list: workingDaysOfWeek,

				// Proxy
				proxy_toggle: false,
				proxy_protocol: 'SOCKS5',
				proxy_host: '',
				proxy_port: 0,
				proxy_auth_toggle: false,
				proxy_auth_username: '',
				proxy_auth_password: '',
			},
			errors: {
				name: {s: false, m: ''},
				brand_id: {s: false, m: ''},
				type: {s: false, m: ''},
				api_url: {s: false, m: ''},
				create_lead: {s: false, m: ''},
				create_customer: {s: false, m: ''},
				get_by_email: {s: false, m: ''},
				get_by_cid: {s: false, m: ''},

				// Extra
				get_deposits: {s: false, m: ''},
				get_balances: {s: false, m: ''},

				// Password related
				password_validation_pattern_string: {s: false, m: ''},
				password_validation_pattern_message: {s: false, m: ''},
			},
			typeAvoidOptions: [],
			typeOptions: [],
			brandOptions: [],
			tree: {
				misc: {},
				creds: {}
			}
		}
	},

	methods: {
			resetPasswordElements() {
				if(this.form.password_validation_pattern_flag === false) {
					this.form.password_validation_pattern_string = '';
					this.form.password_validation_pattern_message = '';
				}
			},
			showEligibleFields() {
				this.showEligibleFieldsModal = true;

			},
			itgTypeChosed($event) {
					setTimeout(() => {
							this.typeAvoidOptions = [];
							this.form.ignore_fields_list = [];
							this.$apix.sendHttpRequest('GET','integration/integration-fields/' + $event.target.value)
									.then(res => {

											this.integrationExists = res.exists;
											this.currentIntegrationFields = JSON.parse(JSON.stringify($currentIntegrationFields)),
											Object.values(res.content).forEach(e => {
													this.currentIntegrationFields.hash[e.params][e.key] = e;
													this.currentIntegrationFields.list[e.params].push(e);
													this.typeAvoidOptions.push(e.key);
											})

											// this.typeAvoidOptions = [...typeAvoidOptions.sort().map(e => Object.assign({}, {link: e, value:e}))]
											this.typeAvoidOptions = [...this.typeAvoidOptions.sort()]

									}).catch(e => {
											console.error(e);

							})
					}, 750);
			},
		shrinkLinksArray (i) {
			this.form.links.splice(i,1);
		},

		querySearch(qs, cb) {
			let typeOptions = this.typeOptions;
			let results = qs ? typeOptions.filter(this.createFilter(qs)) : typeOptions
			cb(results)
		},
		createFilter(queryString) {
			return (link) => {
				return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		addRow(type) {
			this.form[type].push({key: `key_${this.counter[type]}`, value: `value_${this.counter[type]}`});
			this.counter[type]++;
		},
		submit() {
			if (!this.validate()) return this.$notify.error({title: 'Validation Error', message: 'There are errors with the input data'});
			this.buildJSON('creds');
			this.buildJSON('misc');
			if (this.type === 'create' || this.type === 'clone') {
				this.$apix.sendHttpRequest('POST', 'integration/create', {
					data: {
						type: this.form.type,
						api_url: this.form.api_url,
						creds: this.tree.creds,
						misc: this.tree.misc,
						note: this.form.note,
						brand_id: this.form.brand_id,
						ignore_fields_list: this.form.ignore_fields_list,

						// Password validation
						password_validation_pattern_flag: this.form.password_validation_pattern_flag,
						password_validation_pattern_string: this.form.password_validation_pattern_string,
						password_validation_pattern_message: this.form.password_validation_pattern_message,
						password_with_sign_flag: this.form.password_with_sign_flag,

						// Working hours
						working_hours_flag: this.form.working_hours_flag,
						working_hours_list: this.form.working_hours_list,

						// Proxy
						proxy_toggle: this.form.proxy_toggle,
						proxy_protocol: this.form.proxy_protocol,
						proxy_host: this.form.proxy_host,
						proxy_port: this.form.proxy_port,
						proxy_auth_toggle: this.form.proxy_auth_toggle,
						proxy_auth_username: this.form.proxy_auth_username,
						proxy_auth_password: this.form.proxy_auth_password,
					}
				}).then(res => {
					if (!!res.id) this.$router.push({name: 'iframe-list'})
				}).catch(err => console.error(err))
			} else {
				this.$apix.sendHttpRequest('PUT', 'integration/update/' + this.entity_id, {
					data: {
						type: this.form.type,
						api_url: this.form.api_url,
						creds: this.tree.creds,
						misc: this.tree.misc,
						note: this.form.note,
						brand_id: this.form.brand_id,
						ignore_fields_list: this.form.ignore_fields_list,

						// Password validation
						password_validation_pattern_flag: this.form.password_validation_pattern_flag,
						password_validation_pattern_string: this.form.password_validation_pattern_string,
						password_validation_pattern_message: this.form.password_validation_pattern_message,
						password_with_sign_flag: this.form.password_with_sign_flag,

						// Working hours
						working_hours_flag: this.form.working_hours_flag,
						working_hours_list: this.form.working_hours_list,

						// Proxy
						proxy_toggle: this.form.proxy_toggle,
						proxy_protocol: this.form.proxy_protocol,
						proxy_host: this.form.proxy_host,
						proxy_port: this.form.proxy_port,
						proxy_auth_toggle: this.form.proxy_auth_toggle,
						proxy_auth_username: this.form.proxy_auth_username,
						proxy_auth_password: this.form.proxy_auth_password,
					}
				}).then(res => {
					this.$router.push({name: 'iframe-list'})
				}).catch(err => console.error(err))
			}
		},
		validate() {
			this.errors = {
				type: {s: false, m: ''},
				brand_id: {s: false, m: ''},
				api_url: {s: false, m: ''},
				create_lead: {s: false, m: ''},
				create_customer: {s: false, m: ''},
				get_by_email: {s: false, m: ''},
				get_by_cid: {s: false, m: ''},
				get_deposits: {s: false, m: ''},
				get_balances: {s: false, m: ''},

				// Password related
				password_validation_pattern_string: {s: false, m: ''},
				password_validation_pattern_message: {s: false, m: ''},
			}
			let error = false;

			if(this.form.password_validation_pattern_flag) {
				if (this.form.password_validation_pattern_string === '') {
					this.errors.password_validation_pattern_string = {s: true, m: 'Password RegEx cannot be empty'};
					error = true;
				} else if (!isValidRegex(this.form.password_validation_pattern_string)) {
					this.errors.password_validation_pattern_string = {s: true, m: 'Invalid RegEx string'};
					error = true;
				}

				if (this.form.password_validation_pattern_message === '') {
					this.errors.password_validation_pattern_message = {s: true, m: 'Password validation message cannot be empty'};
					error = true;
				}
			}


			if (this.form.type === '') {
				this.errors.type = {s: true, m: 'Type cannot be empty'};
				error = true;
			} else if (!validator.isAscii(this.form.type)) {
				this.errors.type = {s: true, m: 'Type must be alphanumeric'};
				error = true;
			}

			if (this.form.brand_id === '') {
				this.errors.brand_id = {s: true, m: 'Brand is mandatory'};
				error = true;
			}

			// if(this.form.api_url === '') {
			//		 this.errors.api_url = {s:true, m: 'API URL cannot be empty'};
			//		 error = true;
			// } else if(!validator.isURL(this.form.api_url)) {
			//		 this.errors.api_url = {s:true, m: 'API URL malformed'};
			//		 error = true;
			// }
			//
			// if(this.form.create_lead === '') {
			//		 this.errors.create_lead = {s:true, m: 'Type cannot be empty'};
			//		 error = true;
			// } else if(!validator.isAscii(this.form.create_lead)) {
			//		 this.errors.create_lead = {s:true, m: 'Type must be alphanumeric'};
			//		 error = true;
			// }
			//
			// if(this.form.create_customer === '') {
			//		 this.errors.create_customer = {s:true, m: 'Type cannot be empty'};
			//		 error = true;
			// } else if(!validator.isAscii(this.form.create_customer)) {
			//		 this.errors.create_customer = {s:true, m: 'Type must be alphanumeric'};
			//		 error = true;
			// }

			// if(this.form.get_by_email === '') {
			//		 this.errors.get_by_email = {s:true, m: 'Type cannot be empty'};
			//		 error = true;
			// } else if(!validator.isAscii(this.form.get_by_email)) {
			//		 this.errors.get_by_email = {s:true, m: 'Type must be alphanumeric'};
			//		 error = true;
			// }
			//
			// if(this.form.get_by_cid === '') {
			//		 this.errors.get_by_cid = {s:true, m: 'Type cannot be empty'};
			//		 error = true;
			// } else if(!validator.isAscii(this.form.get_by_cid)) {
			//		 this.errors.get_by_cid = {s:true, m: 'Type must be alphanumeric'};
			//		 error = true;
			// }

			return !error;
		},
		buildJSON(type) {
			if (type === 'creds') {
				this.form.creds.forEach(e => {
					this.tree.creds[e.key] = e.value;
				});
			} else {
				this.form.misc.forEach(e => {
					this.tree.misc[e.key] = e.value;
				});
				this.tree.misc['api_url'] = {
					host: this.form.api_url,
					createCustomer: this.form.create_customer,
					createLead: this.form.create_lead,
					getByMail: this.form.get_by_email,
					getByCid: this.form.get_by_cid,
					getDeposits: this.form.get_deposits,
					getBalances: this.form.get_balances,
				}
				this.form.links.forEach(l => {
					this.tree.misc.api_url[l.key] = l.value
				});

				if (this.form.close_cfd_action) this.tree.misc['api_url']['closeCfdAction'] = this.form.close_cfd_action;
				if (this.form.edit_cfd_action) this.tree.misc['api_url']['editCfdAction'] = this.form.edit_cfd_action;
				if (this.form.get_asset_rate) this.tree.misc['api_url']['getAssetRate'] = this.form.get_asset_rate;
				if (this.form.get_assets) this.tree.misc['api_url']['getAssets'] = this.form.get_assets;
				if (this.form.get_cfd_options) this.tree.misc['api_url']['getCfdOptions'] = this.form.get_cfd_options;
				if (this.form.get_options) this.tree.misc['api_url']['getOptions'] = this.form.get_options;
				if (this.form.get_orders) this.tree.misc['api_url']['getOrders'] = this.form.get_orders;
				if (this.form.get_trade_actions) this.tree.misc['api_url']['getTradeActionsReport'] = this.form.get_trade_actions;
				if (this.form.place_cfd_action) this.tree.misc['api_url']['placeCfdAction'] = this.form.place_cfd_action;
				if (this.form.place_order) this.tree.misc['api_url']['placeOrder'] = this.form.place_order;
			}
		}
	},

}
</script>
